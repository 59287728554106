// This file should be edited under:
// <root>/configuration/environment/config.ts
//
export const Config = {
  E2E: true,
  ENVIRONMENT: 'qa',
  MOCK_STATE: '',
  BRANCH_KEY: 'key_test_jmQAgOqcw9K5Fr9t9zGjahncqsgCF1VJ',
  STRIPE_APIKEY:
    'pk_test_51HrTdzIDw3UYhiN0Rlc3URdnnzeyt7K2JDSBjLfbqAmytQ5gybyCdNZEKiXYBJIUZbcBUqiq1FOzdCJWkd1crfwM00WN8n0U4f',
  DISABLE_RECAPTCHA: false,
  AMPLITUDE_API_KEY: '073d0fe7f2baa3fdd37559842c24f4f1',
  AMPLITUDE_DEPLOYMENT_KEY: 'client-9cRraRACsw6xeHOd9YXSHTp1cL9rwxyp',
  GTM_CONTAINER_ID: 'GTM-5FPNXVX8',
  EMBED_ORIGINS_WHITELIST: [
    'securecafenet.com',
    'securecafe.com',
    'rentcafe.com',
    'securercqatolnet.com',
    'securercqatol.com',
    'rcqatol.com',
  ],
  CONSENT_MGMT_SRC: 'https://consent-modules.getflex.com/cm-test/2c99ae90-fc0a-4590-af13-981f06a71746/airgap.js',

  // Datadog Config
  DATADOG_ENV: 'qa',
  DATADOG_SITE: 'datadoghq.com',
  DATADOG_SERVICE_NAME: 'qa-consumer-web-app',
  DATADOG_CLIENT_TOKEN: 'pubc0f62431e2e8e4217ad077cd3d34efd4',
  DATADOG_APPLICATION_ID: '846480fa-f7f5-4e7b-bc10-94dcea04af48',
  DATADOG_ALLOWED_TRACING_URLS: [/https:\/\/.*\.getflex\.com/],
  DATADOG_TRACE_SAMPLE_RATE: 100,

  ITERABLE_API_KEY: 'a267bc77345848b89afe4a8aad2cf0fa',
  FLEX_2_API: 'https://api.qa.getflex.com',

  // Change to https://growth-infra.dev.getflex.com at some point.
  // See https://getflex.atlassian.net/browse/INFRA-1902
  GROWTH_INFRA_API: 'https://growth-infra.development-5241.getflex.com',
  LOG_LEVEL: [],
  SAMPLE_RATE: 10,
  SINGULAR_KEY: 'flex_42f78213',
  SINGULAR_SECRET: '4355c7443d1e865bacb3cc708260660d',
  SINGULAR_BUNDLE_ID: 'com.onboarding.qa.getflex',
}

export const FirebaseConfig = {
  apiKey: 'AIzaSyC_sbpMAZCNW3Ju4vMWGCYfWEzyMeYMFaY',
  authDomain: 'getflex-development.firebaseapp.com',
  databaseURL: 'https://getflex-development.firebaseio.com',
  projectId: 'getflex-development',
  storageBucket: 'getflex-development.appspot.com',
  messagingSenderId: '247201228089',
  appId: '1:247201228089:web:f77137627689c5085f34e8',
  measurementId: 'G-X6DVNVDH9W',
}
